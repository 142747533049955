import UploadIcon from '@mui/icons-material/Publish'
import {If} from 'babel-plugin-jsx-control-statements'
import {isArray} from 'lodash'
import PropTypes from 'prop-types'
import {useRef} from 'react'
import {useCreateResource} from '../../../hooks/api'
import {FileInput} from '../../visual'
import Button from '../Button/Button'

const FileUpload = ({
  app,
  route,
  label,
  required,
  helperText,
  fullWidth,
  inputProps,
  error,
  accept,
  multiple,
  hideUploadButton,
  children,
  onUpload,
  disabled,
  ...props
}) => {
  const createFile = useCreateResource(app, route, {unwrapData: true})
  const inputRef = useRef()

  const handleFileUpload = async (e) => {
    const {files} = inputRef.current
    const listOfFiles = Object.values(files)

    const data = new window.FormData()
    if (!multiple) data.append('file', listOfFiles[0])
    else {
      listOfFiles.forEach((file) => {
        data.append('file[]', file)
      })
    }
    const res = await createFile.mutateAsync({asMultipart: true, data})
    const fileIds = isArray(res) ? res : [res.id]
    if (onUpload) onUpload({files, fileIds}, e)
  }
  if (disabled) return null

  return (
    <FileInput
      fullWidth={fullWidth}
      label={label}
      helperText={helperText}
      error={error}
      required={required}
      onChange={handleFileUpload}
      inputRef={inputRef}
      inputProps={{
        accept,
        multiple,
        ...inputProps,
      }}
      {...props}
    >
      <If condition={!hideUploadButton}>
        <Button icon={<UploadIcon />} component="span">
          {children}
        </Button>
      </If>
    </FileInput>
  )
}

FileUpload.defaultProps = {
  hideUploadButton: false,
}

FileUpload.propTypes = {
  app: PropTypes.string,
  route: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multiple: PropTypes.bool,
  hideUploadButton: PropTypes.bool,
  children: PropTypes.node,
  onUpload: PropTypes.func,
  disabled: PropTypes.bool,
}

export default FileUpload
