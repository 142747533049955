import S from 'fluent-json-schema'
import {MEGASHOP} from '../../../../constants/apps'
import * as megashopSystemSettingNames from '../../../../constants/megashop/megashopSystemSettings'
import * as baseSchemas from '../../baseSchemas'

/**
 * Entries in `system_setting` table:
 */
export const MEGASHOP_HOMEPAGE = {
  name: megashopSystemSettingNames.MEGASHOP_HOMEPAGE,
  // Don't forget to change this schema in SystemSettingsForm.js on frontend
  schema: baseSchemas.object({
    seoDescription: baseSchemas.standardString(),
    seoRobots: baseSchemas.seoRobotsType(),
    type: baseSchemas.contentType().required(),
    value: S.object().required(),
    children: S.array().items(S.object()).raw({nullable: true}),
  }),
  public: true,
  app: MEGASHOP,
}

export const MEGASHOP_FOOTER = {
  name: megashopSystemSettingNames.MEGASHOP_FOOTER,
  schema: baseSchemas.object({
    type: baseSchemas.contentType().required(),
    value: S.object().required(),
    children: S.array().items(S.object()).raw({nullable: true}),
  }),
  public: true,
  app: MEGASHOP,
}

export const MEGASHOP_REGISTRATION_EMAIL = {
  name: megashopSystemSettingNames.MEGASHOP_REGISTRATION_EMAIL,
  schema: baseSchemas
    .object({
      sender: baseSchemas.email().required(),
      content: baseSchemas.object({
        subject: baseSchemas.standardString().required(),
      }),
    })
    .raw({nullable: true})
    .default(null),
  public: false,
  app: MEGASHOP,
}

export const MEGASHOP_REGISTRATION_NOTICE = {
  name: megashopSystemSettingNames.MEGASHOP_REGISTRATION_NOTICE,
  schema: baseSchemas
    .object({
      sender: baseSchemas.email(),
      recipient: baseSchemas.email().required(),
      content: baseSchemas.object({
        subject: baseSchemas.standardString().required(),
      }),
    })
    .raw({nullable: true})
    .default(null),
  public: false,
  app: MEGASHOP,
}

export const MEGASHOP_PASSWORD_RESET_EMAIL = {
  name: megashopSystemSettingNames.MEGASHOP_PASSWORD_RESET_EMAIL,
  schema: baseSchemas
    .object({
      sender: baseSchemas.email().required(),
      content: baseSchemas.object({
        subject: baseSchemas.standardString().required(),
      }),
    })
    .raw({nullable: true})
    .default(null),
  public: false,
  app: MEGASHOP,
}

export const MEGASHOP_PASSWORD_REVERT_EMAIL = {
  name: megashopSystemSettingNames.MEGASHOP_PASSWORD_REVERT_EMAIL,
  schema: baseSchemas
    .object({
      sender: baseSchemas.email().required(),
      content: baseSchemas.object({
        subject: baseSchemas.standardString().required(),
      }),
    })
    .raw({nullable: true})
    .default(null),
  public: false,
  app: MEGASHOP,
}

export const MEGASHOP_ORDER_SUBMIT_NOTICE = {
  name: megashopSystemSettingNames.MEGASHOP_ORDER_SUBMIT_NOTICE,
  schema: baseSchemas
    .object({
      sender: baseSchemas.email(),
      recipient: baseSchemas.email().required(),
      content: baseSchemas.object({
        subject: baseSchemas.standardString().required(),
      }),
    })
    .raw({nullable: true})
    .default(null),
  public: false,
  app: MEGASHOP,
}

export const MEGASHOP_CONTENT_AFTER_PRODUCTS = {
  name: megashopSystemSettingNames.MEGASHOP_CONTENT_AFTER_PRODUCTS,
  schema: baseSchemas.object({
    type: baseSchemas.contentType().required(),
    value: S.object().required(),
    children: S.array().items(S.object()).raw({nullable: true}),
  }),
  public: true,
  app: MEGASHOP,
}

export const MEGASHOP_PASSWORD_SET_EMAIL = {
  name: megashopSystemSettingNames.MEGASHOP_PASSWORD_SET_EMAIL,
  schema: baseSchemas
    .object({
      sender: baseSchemas.email().required(),
      content: baseSchemas.object({
        subject: baseSchemas.standardString().required(),
      }),
    })
    .raw({nullable: true})
    .default(null),
  public: false,
  app: MEGASHOP,
}

export const ALL_MEGASHOP_SYSTEM_SETTINGS = [
  MEGASHOP_HOMEPAGE,
  MEGASHOP_FOOTER,
  MEGASHOP_REGISTRATION_EMAIL,
  MEGASHOP_REGISTRATION_NOTICE,
  MEGASHOP_PASSWORD_RESET_EMAIL,
  MEGASHOP_PASSWORD_REVERT_EMAIL,
  MEGASHOP_ORDER_SUBMIT_NOTICE,
  MEGASHOP_CONTENT_AFTER_PRODUCTS,
  MEGASHOP_PASSWORD_SET_EMAIL,
]
