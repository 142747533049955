import FindInPageIcon from '@mui/icons-material/FindInPage'
import {useNavigate} from '@reach/router'
import {ErrorScreen} from '../visual/'

const NotFound = () => {
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1)
  }
  const handleNavigateHome = () => {
    navigate('/')
  }

  return (
    <ErrorScreen
      Icon={FindInPageIcon}
      title="Nenájdené"
      buttonLabel="Späť"
      homeButtonLabel="Domov"
      onHomeClick={handleNavigateHome}
      onClick={handleBack}
    >
      <div>Zadali ste zlú adresu URL alebo link...</div>
    </ErrorScreen>
  )
}

export default NotFound
