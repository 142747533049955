import PropTypes from 'prop-types'
import {
  TableCellHeader,
  TableHead,
  TableRow,
  TableSortLabel,
} from '../../visual'

const DatagridHead = ({headerGroups, ...props}) => (
  <TableHead {...props}>
    {headerGroups.map((headerGroup) => (
      <TableRow key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <TableCellHeader
            key={column.id}
            {...column.getHeaderProps()}
            padding={column.checkbox ? 'checkbox' : 'normal'}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            <TableSortLabel
              active={column.isSorted}
              direction={column.isSortedDesc ? 'desc' : 'asc'}
            >
              {column.render('Header')}
            </TableSortLabel>
          </TableCellHeader>
        ))}
      </TableRow>
    ))}
  </TableHead>
)

DatagridHead.propTypes = {
  headerGroups: PropTypes.array.isRequired,
}

export default DatagridHead
