import {Stack} from '@mui/material'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import Button from '../../containers/Button/Button'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    paddingTop: '10%',
  },
  icon: {
    fontSize: 200,
  },
  button: {
    marginTop: 25,
  },
}))

const ErrorScreen = ({
  Icon,
  title,
  homeButtonLabel,
  buttonLabel,
  onHomeClick,
  onClick,
  children,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} />
      <h1>{title}</h1>
      {children}
      <Stack useFlexGap direction="row" alignItems="center" gap={2}>
        <Button
          onClick={onHomeClick}
          className={classes.button}
          color="primary"
        >
          {homeButtonLabel}
        </Button>
        <Button onClick={onClick} className={classes.button} color="secondary">
          {buttonLabel}
        </Button>
      </Stack>
    </div>
  )
}

ErrorScreen.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string,
  homeButtonLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  onHomeClick: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default ErrorScreen
