import MuiBox from '@mui/material/Box'
import PropTypes from 'prop-types'

export const override = {
  // Empty
}

const Box = ({children, ...props}) => <MuiBox {...props}>{children}</MuiBox>

Box.propTypes = {
  children: PropTypes.node,
}

export default Box
