import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import {useMemo} from 'react'
import {useSortBy, useTable, usePagination} from 'react-table'
import {TableContainer, TablePagination} from '../../visual'
import DatagridTable from './DatagridTable'
import rowSelect from './rowSelect'

const SimpleDatagrid = ({
  data,
  columns,
  rowStyles,
  columnProps,
  onRowClick,
  disablePagination,
  disableSortBy,
  pageOptions,
  selectedIds,
  setSelectedIds,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      data,
      columns,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            selectedIds,
            setSelectedIds,
          }),
          // Rule incorrectly enumerates used references
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, selectedIds, setSelectedIds],
        )
      },
      disableSortBy,
      ...props,
    },
    useSortBy,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((allColumns) =>
        selectedIds ? rowSelect(allColumns) : allColumns,
      )
    },
  )

  return (
    <TableContainer>
      <DatagridTable
        selectedIds={selectedIds}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        prepareRow={prepareRow}
        headerGroups={headerGroups}
        rows={disablePagination ? rows : page}
        rowStyles={rowStyles}
        columnProps={columnProps}
        onRowClick={onRowClick}
      />
      <If condition={!disablePagination}>
        <TablePagination
          rowsPerPage={pageSize}
          page={pageIndex}
          count={rows.length}
          rowsPerPageOptions={pageOptions}
          onRowsPerPageChange={(e) => setPageSize(e.target.value)}
          onPageChange={(_e, page) => gotoPage(page)}
        />
      </If>
    </TableContainer>
  )
}

SimpleDatagrid.defaultProps = {
  pageOptions: [5, 10, 25],
  disablePagination: false,
  disableSortBy: false,
}

SimpleDatagrid.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  disablePagination: PropTypes.bool,
  disableSortBy: PropTypes.bool,
  rowStyles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  columnProps: PropTypes.object,
  pageOptions: PropTypes.arrayOf(PropTypes.number),
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  setSelectedIds: PropTypes.func,
  onRowClick: PropTypes.func,
}

export default SimpleDatagrid
